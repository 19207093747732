
<template>
  <div>
    <a-tabs :ref="'tabs'" size="small" default-active-key="1" @change="callback" :tabBarStyle="{marginBottom: '0px'}" >
      <a-tab-pane key="1" tab="1d">
        <StockPanel  v-if="!isFetching" key="s1" 
                     :fav="fav" 
                     id="1d"
                     @add-fav="addFav"
                     @remove-fav="removeFav"
                     url="/api/stocks/day/1"  
                     :datasource="list" 
                     @search-symbol="searchSymbol"/>
      </a-tab-pane>
      <a-tab-pane key="2" tab="2d" force-render>
        <StockPanel v-if="!isFetching" key="s2" :fav="fav" id="2d" @add-fav="addFav"
                     @remove-fav="removeFav"
                     url="/api/stocks/dates/2" :datasource="list" />
      </a-tab-pane>
      <a-tab-pane key="3" tab="5d">
        <StockPanel v-if="!isFetching" key="s3" :fav="fav"  id="5d" @add-fav="addFav"
                     @remove-fav="removeFav"
                     url="/api/stocks/dates/5" :datasource="list" />
      </a-tab-pane>
      <a-tab-pane key="4" tab="30d">
        <StockPanel  v-if="!isFetching" key="s4" id="30d"  @add-fav="addFav"
                     @remove-fav="removeFav"
                     url="/api/stocks/dates/30" :datasource="list" />
      </a-tab-pane>
      <a-tab-pane key="5" tab="60d">
        <StockPanel v-if="!isFetching" key="s5"  id="60d" @add-fav="addFav"
                     @remove-fav="removeFav"
                     url="/api/stocks/dates/60" :datasource="list" />
      </a-tab-pane>
      <a-tab-pane key="6" tab="180d">
        <StockPanel  v-if="!isFetching" key="s6" id="180d"  @add-fav="addFav"
                     @remove-fav="removeFav"
                     url="/api/stocks/dates/180" :datasource="list" />
      </a-tab-pane>
      <a-tab-pane key="7" tab="This Year">
        <StockPanel v-if="!isFetching" key="s7"  id="1y" @add-fav="addFav"
                     @remove-fav="removeFav"
                     url="/api/stocks/dates/360" :datasource="list" />
      </a-tab-pane>
      <a-tab-pane key="8" tab="5 Year">
        <StockPanel v-if="!isFetching" key="s8" id="5y"  @add-fav="addFav"
                     @remove-fav="removeFav"
                     url="/api/stocks/dates/1800" :datasource="list" />
      </a-tab-pane>
      <!-- <a-tab-pane key="9" tab="Custom">
        <iframe src="https://www.nasdaq.com/market-activity/stocks/aapl" style="position:fixed; top:0; left:0; bottom:0; right:0; width:100%; height:100%; border:none; margin:0; padding:0; overflow:hidden; z-index:999999;"></iframe>
      </a-tab-pane> -->
    </a-tabs>
  </div>
</template>
<script>

import StockPanel from '../StockPanel.vue'
import { fetchSymbolData, fetchFavourites, removeFavourite, addFavourite } from '@/api/stocks'

export default {
  mounted: function () {
      console.log('tab one MAIN')
      fetchFavourites().then(response => {
         console.log('RESPONSE')
         console.log(response)
         this.fav = [response.content]
         console.log(this.fav)
         this.isFetching = false
      })
     
  },
  data() {
    return {
      isFetching: true,
      list: [],
      fav: []
    };
  },
  methods: {
    callback(key) {
      console.log(key);
    },
    removeFav(t) {
      console.log('Star Removed ' + t.symbol) 
      removeFavourite({symbol: t.symbol}).then(response => {
          console.log(response)
          let favObj = this.fav[0]
          delete favObj[t.symbol]
          this.fav = [favObj]
      })
    },
    addFav(t) {
      console.log('Star Added ' + t.symbol) 
      addFavourite({symbol: t.symbol}).then(response => {
        console.log(response)
        let favObj = this.fav[0]
        favObj[t.symbol] = '1'
        this.fav = [favObj]
      })
    },
    searchSymbol(t) {
      console.log(t)
      console.log('searchSymbol ' + t.data + ' ' + t.data.length)
    //  if (t.data.length > 2) {
      if (t.data) {
        fetchSymbolData('?s=' + t.data).then(response => {
          console.log(response)
          this.list = response.data
          this.list = JSON.parse(JSON.stringify(this.list))
      })
      } else {
        this.list = []
      }
        
   //   }
      
      
    }
  },
   components: {StockPanel}
};
</script>

<style>
.ant-tabs-bar {
  margin-bottom:0px;
}
</style>