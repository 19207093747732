<template>
    <div style="padding:10px;background-color:#f8f8f8;">
        <a-row style="padding-left:10px;padding-right:10px;margin-bottom:5px;">
            <a-col :span="6" style="padding-right:10px">
                <a-icon v-if="favourite" type="star" :style="{ fontSize: '16px', color: '#08c',paddingRight: '10px' }" @click="hideFav()" theme="filled" />

      <a-icon v-if="!favourite" type="star" :style="{ fontSize: '16px', color: '#08c',paddingRight: '10px' }" @click="showFav()" />
                  <SymbolAutoSelect :ref="id + 'symbol'" @select-symbol="selectSymbol" :datasource="datasource"  @search-symbol="searchSymbol" />

    
                  <a-select  mode="multiple"  @select="setStockType" default-value="All" :class="!!stockType && stockType.length != 3? 'ddown': ''" v-model="stockType" style="width: 100%;margin-top:4px" @change="handleChange">
      <a-select-option value="N">
        Stock
      </a-select-option>
      <a-select-option value="Y">
        ETF 
      </a-select-option>
     
      <a-select-option value="U">
        Uncategorized
      </a-select-option>
      
       </a-select>
            </a-col>
            <!-- <a-col :span="1">
                </a-col> -->
<a-col :span="18">
             <a-select mode="multiple" v-model="sector" :default-value="defaultSector" :class="!!sector && sector.length < 11? 'ddown': ''" style="width: 100%;" @change="handleChange">
      <a-select-option value="Uncategorized">
        Uncategorized
      </a-select-option>
      
      <a-select-option value="Technology">
        Technology
      </a-select-option>
     
      <a-select-option value="Utilities">
        Utilities
      </a-select-option>

          <a-select-option value="Industrials">
        Industrials
      </a-select-option>
     
      <a-select-option value="Healthcare">
        Healthcare
      </a-select-option>

   <a-select-option value="Consumer Defensive">
        Consumer Defensive
      </a-select-option>

          <a-select-option value="Financial Services">
        Financial Services
      </a-select-option>
     
      <a-select-option value="Basic Materials">
        Basic Materials
      </a-select-option>

       <a-select-option value="Consumer Cyclical">
        Consumer Cyclical
      </a-select-option>

          <a-select-option value="Communication Services">
        Communication Services
      </a-select-option>
     
      <a-select-option value="Energy">
        Energy
      </a-select-option>


    </a-select>
     </a-col>
        </a-row>

        <a-row>
            <a-col :span="12" style="padding-left:10px;padding-right:10px">
                <StockTable :min="rMin" 
                            :max="rMax" 
                            :ref="id + 'rise'"
                            :list="riseList" 
                            :defaultPrice="riseDefaultPrice" 
                            :defaultPercentage="riseDefaultPercentage"
                            mode="r"
                            :label="riseLabel"
                            :range="riseRange"
                            @fetchStock="fetchStock"
                            :datasource="datasource"
                            @search-symbol="searchSymbol"
                            :fav="fav"
                            @add-fav="addFavourite"
                            @remove-fav="removeFavourite"
                             > </StockTable>
      </a-col>
      <a-col :span="12" style="padding-left:10px;padding-right:10px">
         <StockTable :min="dMin" 
                            :max="dMax" 
                            :list="dropList" 
                            :defaultPrice="dropDefaultPrice" 
                            :defaultPercentage="dropDefaultPercentage"
                            mode="d"
                            :ref="id + 'drop'"
                            :label="dropLabel"
                            :range="dropRange"
                            @fetchStock="fetchStock"
                            :datasource="datasource"
                            @search-symbol="searchSymbol"
                            :fav="fav"
                            @add-fav="addFavourite"
                            @remove-fav="removeFavourite"
                             > </StockTable>
      </a-col>
         
        </a-row>
    </div>
</template>

<script>

import { fetchStockData } from '@/api/stocks'
import StockTable from './StockTable.vue'
import SymbolAutoSelect from './SymbolAutoSelect.vue'

export default {
  data() {
    return {
        riseList: [],
        dropList: [],
        rMin: 0,
        rMax: 5000,
        dMin: 0,
        favourite: false,
        dMax: 5000,
        riseLabel: 'Rise',
        dropLabel: 'Drop',
        riseRange:'',
        dropRange:'',
        stockPrice: [1,5000],
        riseDefaultPercentage: [0,100],
        riseDefaultPrice: [0,5000],
        dropDefaultPercentage: [0,100],
        dropDefaultPrice: [0,5000],
        stockType: ['U','N', 'Y'],
        defaultStockType: ['U','Y','N' ],
        defaultSector: ['Uncategorized','Technology','Utilities','Industrials','Healthcare','Consumer Defensive','Financial Services','Basic Materials','Consumer Cyclical','Communication Services','Energy'],
        sector: undefined,
        symbol: '',
        columns: [
            {
                title: 'Symbol',
                dataIndex: 'symbol',
                width: '20%',
                key: 'symbol'
            },
            {
                title: 'Open Cost',
                dataIndex: 'open_cost',
                width: '30%',
                key: 'open_cost'
            },
            {
                title: 'Close Cost',
                dataIndex: 'close_cost',
                width: '30%',
                key: 'close_cost'
            },
            {
                title: '%',
                dataIndex: 'percentage',
                width: '20%',
                key: 'percentage'
            },
        ]
    };
  },
  mounted: function () {
      console.log('tab one panel ' + JSON.stringify(this.fav))
      this.sector = this.defaultSector
      fetchStockData(this.url, '?mode=r&limit=100&offset=0').then(response => {
          console.log(response)
          this.riseList =  response.content
          this.riseRange = response.range 
      })
      fetchStockData(this.url,'?mode=d&limit=100&offset=0').then(response => {
          console.log(response)
          this.dropList =  response.content
          this.dropRange = response.range 
      })
  },
  components: {StockTable, SymbolAutoSelect},
  props: ['url','datasource', 'fav','id'],
  methods: {
    callback(key) {
      console.log(key);
    },
    filterData() {
        console.log(this.$refs[this.id + 'symbol'].symbol)
   
     // this.$refs[this.id + 'symbol'].symbol = ''
      console.log(this.$refs[this.id + 'symbol'])
        let symbol1 = this.$refs[this.id + 'symbol'].symbol?this.$refs[this.id + 'symbol'].symbol: ''
    
      
      let desc = ''; 
      let params = this.$refs[this.id + 'rise']
      params.startPercentage = params.stockPercentage[0]
      params.endPercentage = params.stockPercentage[1]
      params.startStockPrice = params.stockPrice[0]
      params.endStockPrice = params.stockPrice[1]
      params.mode = 'r'
      
       desc +=  `?limit=${params.limit}&offset=${params.offset}&mode=${params.mode}&start_percent=${params.startPercentage}`;
       desc += `&end_percent=${params.endPercentage}&start_stock_price=${params.startStockPrice}`;
       desc += `&end_stock_price=${params.endStockPrice}`;
       desc += `&symbol=${symbol1}`;
       

       let stockTypeString = undefined;
       if (this.stockType && this.stockType.length > 0) {
          for (let j = 0; j < this.stockType.length; j++) {
             if (stockTypeString) {
                stockTypeString = stockTypeString + ',' + this.stockType[j] ;
             } else {
                stockTypeString =  this.stockType[j] ;
             }
             
          } 
      }

      desc += `&stock_type=${stockTypeString}`;

       let sectorString = undefined;
       if (this.sector && this.sector.length > 0) {
          for (let j = 0; j < this.sector.length; j++) {
             if (sectorString) {
                sectorString =  sectorString + ',' + this.sector[j] ;
             } else {
                sectorString =  this.sector[j] ;
             }
             
          } 
      }
       

       desc += `&sector=${sectorString}`;
    
       if (this.favourite)
       {
         desc += `&fav=1`;
       }
       else {
         desc += `&fav=0`;
       }
    

       fetchStockData(this.url, desc).then(response => {
          console.log(response)
          this.riseList =  response.content
          
      })

      desc = ''; 
      params = this.$refs[this.id + 'drop']
      params.startPercentage = params.stockPercentage[0]
      params.endPercentage = params.stockPercentage[1]
      params.startStockPrice = params.stockPrice[0]
      params.endStockPrice = params.stockPrice[1]
      params.mode = 'd'
      
       desc +=  `?limit=${params.limit}&offset=${params.offset}&mode=${params.mode}&start_percent=${params.startPercentage}`;
       desc += `&end_percent=${params.endPercentage}&start_stock_price=${params.startStockPrice}`;
       desc += `&end_stock_price=${params.endStockPrice}`;
       desc += `&symbol=${symbol1}`;
       desc += `&stock_type=${this.stockType}`;
       desc += `&sector=${sectorString}`;

       if (this.favourite)
       {
         desc += `&fav=1`;
       }
       else {
         desc += `&fav=0`;
       }
    
       fetchStockData(this.url, desc).then(response => {
          console.log(response)
          
             this.dropList =  response.content
      })

    },
    selectSymbol(t) {
      this.symbol = t.data
      this.favourite = false
     // alert('select symbol called)')
        this.$refs[this.id + 'rise'].stockPercentage = this.riseDefaultPercentage
      this.$refs[this.id + 'rise'].stockPrice = this.riseDefaultPrice
      this.$refs[this.id + 'drop'].stockPercentage = this.dropDefaultPercentage
      this.$refs[this.id + 'drop'].stockPrice = this.dropDefaultPrice
      this.stockType = this.defaultStockType
      this.sector = this.defaultSector 
      this.filterData()
    

    },
    setSector() {
      //    this.$refs[this.id + 'rise'].stockPercentage = this.riseDefaultPercentage
      // this.$refs[this.id + 'rise'].stockPrice = this.riseDefaultPrice
      // this.$refs[this.id + 'drop'].stockPercentage = this.dropDefaultPercentage
      // this.$refs[this.id + 'drop'].stockPrice = this.dropDefaultPrice
     // this.stockType = this.defaultStockType
    //  this.sector = this.defaultSector 
       this.filterData()
    },
    setStockType() {
         this.$refs[this.id + 'rise'].stockPercentage = this.riseDefaultPercentage
      this.$refs[this.id + 'rise'].stockPrice = this.riseDefaultPrice
      this.$refs[this.id + 'drop'].stockPercentage = this.dropDefaultPercentage
      this.$refs[this.id + 'drop'].stockPrice = this.dropDefaultPrice
     // this.stockType = this.defaultStockType
      this.sector = this.defaultSector 
       this.filterData()
    },
    searchSymbol(t) {
      console.log('searchSymbol1 ' + t)
      if (t.data) {
this.$emit('search-symbol', t) 
      } else {
        console.log('symbol is blank')
        this.selectSymbol(t)
      }
      
    },
    addFavourite(t) {
      this.$emit('add-fav', t)
    },
    showFav() {
      this.favourite = true
         this.$refs[this.id + 'rise'].stockPercentage = this.riseDefaultPercentage
      this.$refs[this.id + 'rise'].stockPrice = this.riseDefaultPrice
      this.$refs[this.id + 'drop'].stockPercentage = this.dropDefaultPercentage
      this.$refs[this.id + 'drop'].stockPrice = this.dropDefaultPrice
      this.stockType = this.defaultStockType
      this.sector = this.defaultSector 

      this.filterData()

    },
    hideFav() {
      this.favourite = false
         this.$refs[this.id + 'rise'].stockPercentage = this.riseDefaultPercentage
      this.$refs[this.id + 'rise'].stockPrice = this.riseDefaultPrice
      this.$refs[this.id + 'drop'].stockPercentage = this.dropDefaultPercentage
      this.$refs[this.id + 'drop'].stockPrice = this.dropDefaultPrice
      this.stockType = this.defaultStockType
      this.sector = this.defaultSector 
      this.filterData()

    },
    removeFavourite(t) {
      console.log('Panel remove fav')
      this.$emit('remove-fav', t)
    },
    handleChange(e)
    {
        console.log(e)
        this.filterData()

    } ,
    fetchStock(params) {
       let desc = ''; 
       let symbol1 = this.$refs[this.id + 'symbol'].symbol?this.$refs[this.id + 'symbol'].symbol: ''
       desc +=  `?limit=${params.limit}&offset=${params.offset}&mode=${params.mode}&start_percent=${params.startPercentage}`;
       desc += `&end_percent=${params.endPercentage}&start_stock_price=${params.startStockPrice}`;
       desc += `&end_stock_price=${params.endStockPrice}`;
       
       let sectorString = undefined;
       if (this.sector && this.sector.length > 0) {
          for (let j = 0; j < this.sector.length; j++) {
             if (sectorString) {
                sectorString = sectorString + ',' + this.sector[j] ;
             } else {
                sectorString =  this.sector[j] ;
             }
             
          } 
      }

      let stockTypeString = undefined;
       if (this.stockType && this.stockType.length > 0) {
          for (let j = 0; j < this.stockType.length; j++) {
             if (stockTypeString) {
                stockTypeString = stockTypeString + ',' + this.stockType[j] ;
             } else {
                stockTypeString =  this.stockType[j] ;
             }
             
          } 
      }

      desc += `&stock_type=${stockTypeString}`;

      desc += `&symbol=${symbol1}`;
    //  desc += `&stock_type=${this.stockType}`;
      desc += `&sector=${sectorString}`;

       fetchStockData(this.url, desc).then(response => {
          console.log(response)
          if (params.mode === 'r') {
             this.riseList =  response.content
          } else {
             this.dropList =  response.content
          }
      })
    }
  },
};
</script>

<style scoped>
.ant-card-bordered {
    border: 1px solid #eaeaea;
}

.ant-card-head-title {
    color: #4d4d4d;
}


</style>