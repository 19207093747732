<template>
  <a-auto-complete
    :data-source="datasource"
    placeholder="Enter Symbol Here"
    :defaultActiveFirstOption="false"
    @search="searchSymbol"
    @select="selectSymbol"
    v-model="symbol"
    :style="!!symbol? 'background-color:#fffca2': ''" 
    allowClear
    @change="changeSymbol"
  >
  <template slot="default">
    <input :style="!!symbol? 'background-color:#fffca2;height:29px': ''"  />
  </template>
  </a-auto-complete>
</template>
<script>
export default {
  data() {
    return {
      symbol: ''
    };
  },
  props: ['datasource'],
  methods: {
    searchSymbol(t) {
       console.log(t)
       this.$emit('search-symbol', {data: t})
    },
    changeSymbol(t) {
       console.log(t + " " + t)
    },
    selectSymbol(t) {
       console.log(t)
       this.$emit('select-symbol', {data: t})
     
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toUpperCase().indexOf(input.toUpperCase()) >= 3
      );
    },
  },
};
</script>