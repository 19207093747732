import axios from 'axios'
import { message , Modal} from 'ant-design-vue'
import store from '@/store'
import { getToken, setToken } from '@/utils/auth'

// create an axios instance
const service = axios.create({
 // baseURL: 'http://localhost:3015', // url = base url + request url
  baseURL: 'https://stocks-api-r1.herokuapp.com',
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 120000 // request timeout
})

// request interceptor
service.interceptors.request.use(
  config => {
    // do something before request is sent

    if (store.getters.token) {
      // let each request carry token
      // ['X-Token'] is a custom headers key
      // please modify it according to the actual situation
      config.headers['Authorization'] = 'Bearer ' + getToken()
    }
    return config
  },
  error => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
  */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  response => {
    const res = response.data
    console.log(response.status)
    // if the custom code is not 20000, it is judged as an error.
    if (res.status === 'E') {
      // Message({
      //   message: res.message || 'Error',
      //   type: 'error',
      //   duration: 5 * 1000
      // })
      message.error( res.message || 'Error'
        )

      // 50008: Illegal token; 50012: Other clients logged in; 50014: Token expired;
      if (res.code === 50008 || res.code === 50012 || res.code === 50014) {
        // to re-login
        // MessageBox.confirm('You have been logged out, you can cancel to stay on this page, or log in again', 'Confirm logout', {
        //   confirmButtonText: 'Re-Login',
        //   cancelButtonText: 'Cancel',
        //   type: 'warning'
        // }).then(() => {
        //   store.dispatch('user/resetToken').then(() => {
        //     location.reload()
        //   })
        // })
        Modal.confirm({
          title: 'Confirm logout',
          content: 'You have been logged out, you can cancel to stay on this page, or log in again',
          okText: 'Re-Login',
          onOk() {
               store.dispatch('user/resetToken').then(() => {
               location.reload()
               })
          },
          onCancel() {},
        });
      }
      return Promise.reject(new Error(res.message || 'Error'))
    } else {
      if (res.token) { 
         setToken(res.token)
      }
      return res
    }
  },
  error => {
    console.log(error.message)
    if (error.message.includes('401')) {
      Modal.confirm({
        title: 'Unauthorized',
        content: 'You have been logged out, you can cancel to stay on this page, or log in again',
        okText: 'Re-Login',
        onOk() {
             store.dispatch('user/resetToken').then(() => {
             location.reload()
             })
        },
        onCancel() {},
      });
      // MessageBox.confirm('You have been logged out, you can cancel to stay on this page, or log in again', 'Confirm logout', {
      //   confirmButtonText: 'Re-Login',
      //   cancelButtonText: 'Cancel',
      //   type: 'warning'
      // }).then(() => {
      //   store.dispatch('user/resetToken').then(() => {
      //     location.reload()
      //   })
      // })
    } else {
      message.error( error.message
      )
    }
    
    return Promise.reject(error)
  }
)

export default service
