// import axios from 'axios'

// const service = axios.create({
//    //  baseURL: 'http://192.168.86.77:3015', // url = base url + request url
//      baseURL: 'http://localhost:3015',
//     // withCredentials: true, // send cookies when cross-domain requests
//     timeout: 120000 // request timeout
//   })

import request from '@/utils/request'


export function getOneDayStock(params, query) {
   return request({
        url: `/api/stocks/day/1${params}`,
        method: 'get',
        params: query
      })
    
}

export function fetchSymbolData(params, query) {
  return request({
       url: `/api/stocks/symbols${params}`,
       method: 'get',
       params: query
     })
   
} 

export function addFavourite(data) {
  return request({
       url: `/api/stocks/symbol/favourite`,
       method: 'post',
       data: data
     })
   
} 

export function removeFavourite( data) {
  return request({
       url: `/api/stocks/symbol/favourite/delete`,
       method: 'post',
       data: data
     })
   
} 

export function fetchFavourites() {
  return request({
       url: `/api/stocks/symbol/favourites`,
       method: 'get'
     })
   
} 



export function fetchStockData(url, params, query) {
  return request({
       url: `${url}${params}`,
       method: 'get',
       params: query
     })
   
}