<template>
     <a-card size="small" :title="label" >
        <a slot="extra" style="font-size:12px;color:#FFA500" href="#">{{range}}</a>
             <a-row>
                 <a-col :span="5">
      <label>% (<span style="color:#FFA500">{{stockPriceRangeDesc(stockPercentage)}} </span>)</label>
    <a-slider  @afterChange="onChange" range  v-model="stockPercentage" />
     </a-col >
      <a-col :span="1">
      </a-col>
      <a-col :span="18">
      <label>Stock Price ( <span style="color:#FFA500"> {{stockPriceRangeDesc(stockPrice)}} </span>)</label>
    <a-slider @afterChange="onChange" range :min="min" :max="max" defaul-value="20"  v-model="stockPrice" />
     </a-col>
     <!-- <a-col :span="1">
      </a-col> -->
      <!-- <a-col :span="6">
      <label>Page Size ( <span style="color:#FFA500"> {{limit}} </span>)</label>
    <a-slider @afterChange="onChange" :max="maxSize" defaul-value="100" :step="stepSize" v-model="limit" />
     </a-col > -->
   </a-row>
 
    <a-row>
          <!-- <a-select mode="multiple" :default-value="['Not Categorised','Technology','Utilities','Industrials','Healthcare','Consumer Defensive','Financial Services','Basic Materials','Consumer Cyclical','Communication Services','Energy']" style="width: 100%" @change="handleChange">
      <a-select-option value="Not Categorised">
        Not Categorised
      </a-select-option>
      
      <a-select-option value="Technology">
        Technology
      </a-select-option>
     
      <a-select-option value="Utilities">
        Utilities
      </a-select-option>

          <a-select-option value="Industrials">
        Industrials
      </a-select-option>
     
      <a-select-option value="Healthcare">
        Healthcare
      </a-select-option>

   <a-select-option value="Consumer Defensive">
        Consumer Defensive
      </a-select-option>

          <a-select-option value="Financial Services">
        Financial Services
      </a-select-option>
     
      <a-select-option value="Basic Materials">
        Basic Materials
      </a-select-option>

       <a-select-option value="Consumer Cyclical">
        Consumer Cyclical
      </a-select-option>

          <a-select-option value="Communication Services">
        Communication Services
      </a-select-option>
     
      <a-select-option value="Energy">
        Energy
      </a-select-option>


    </a-select> -->
   
    </a-row>

      <a-row>
       <a-button-group>
      <a-button @click="onBack"  type="link" :disabled="this.offset === 0"> back </a-button>
      <a-button @click="onNext" type="link" :disabled="this.list.length < this.limit"> next </a-button>
    </a-button-group>
       <a-select  v-model="limit" style="width: 80px;margin-bottom:5px" @change="onChange">
      <a-select-option value="100">
        100
      </a-select-option>
      <a-select-option value="150">
        150
      </a-select-option>
     
      <a-select-option value="200">
        200
      </a-select-option>
      <a-select-option value="500">
        500
      </a-select-option>
    </a-select>
       <!-- <a-select default-value="All" style="width: 80px" @change="handleChange">
      <a-select-option value="All">
        All
      </a-select-option>
      <a-select-option value="ETF">
        ETF
      </a-select-option>
     
      <a-select-option value="Stock">
        Stock
      </a-select-option>
      
       </a-select> -->

       <!-- <SymbolAutoSelect :datasource="datasource"  @search-symbol="searchSymbol" /> -->

       <!-- <a-icon type="star" :style="{ fontSize: '16px', color: '#08c' }" @click="showFav()" theme="filled" /> -->
    </a-row>

        <a-table :bordered="false" :data-source="list" :columns="columns" size="small" :pagination="false">
            <span slot="symbol" slot-scope="text, record">
                
                <a-icon v-if="!!fav && fav.length > 0 && !!fav[0][record.symbol]" type="star" :style="{ fontSize: '16px', color: '#08c' }" @click="removeFav(record.symbol)" theme="filled" />
               
             
              <a-icon v-if="!fav ||(!!fav && fav.length == 0) || (!!fav && fav.length > 0 && !fav[0][record.symbol]) " type="star" :style="{ fontSize: '16px', color: '#08c' }" @click="addFav(record.symbol)" />
           
               <a target="_blank" :href="'https://finance.yahoo.com/quote/' + record.symbol"> {{ text }} </a>
                <p style="font-size:9px">  {{!!record.security_name && record.security_name.substring(0,80)}} </p>
            </span>
            <span slot="sector"  style="font-size:12px" slot-scope="text">
              {{ text }} 
              </span>
              <span slot="etf"   style="font-size:12px"  slot-scope="text">
              {{ text === 'N'? 'Stock': (text === 'Y'?'ETF':'Uncategorized') }} 
              </span>
         </a-table>
         </a-card>
</template>

<script>

// import SymbolAutoSelect from './SymbolAutoSelect.vue'
// import { removeFavourite, addFavourite } from '@/api/stocks'

export default {
  components: {},
  data() {
    return {
        limit: 100,
        offset: 0,
        maxSize: 500,
        stepSize: 50,
        stockPrice: undefined,
        stockPercentage: undefined,
        pageSize: 100,
        columns: [
            {
                title: 'Symbol',
                dataIndex: 'symbol',
                width: '36%',
                key: 'symbol',
                scopedSlots: { customRender: 'symbol' }
            },
            {
                title: 'Open',
                dataIndex: 'open_cost',
                width: '12%',
                key: 'open_cost'
            },
            {
                title: 'Close',
                dataIndex: 'close_cost',
                width: '12%',
                key: 'close_cost'
            },
            {
                title: '%',
                dataIndex: 'percentage',
                width: '12%',
                key: 'percentage'
            },
            {
                title: 'Type',
                dataIndex: 'etf',
                width: '10%',
                key: 'etf',
                scopedSlots: { customRender: 'etf' }
            },
             {
                title: 'Sector',
                dataIndex: 'sector',
                width: '18%',
                key: 'sector',
                scopedSlots: { customRender: 'sector' }
            },
        ]
    };
  },
  props: 
    [ 'min' , 'max', 'list', 'defaultPercentage', 'defaultPrice', 'mode', 'label', 'range','datasource', 'fav']
  ,
  mounted: function () {
      console.log('tab one' + this.fav)
      this.stockPrice = this.defaultPrice
      this.stockPercentage = this.defaultPercentage

  },

  methods: {
    callback(key) {
      console.log(key);
    },
    removeFav(symbol) {
      console.log('Star Removed ' + symbol) 
       this.$emit('remove-fav', {symbol: symbol})
    },
    addFav(symbol) {
      console.log('Star Added ' + symbol) 
      this.$emit('add-fav', {symbol: symbol})
    },
    showFav() {

    },
    handleChange(e)
    {
        console.log(e)

    } ,
    searchSymbol(t) {
      console.log('searchSymbol9 ' + t)
      this.$emit('search-symbol', t)

    },
    stockPriceRangeDesc(val) {
        if(!val) return ''
        let desc = val[0] + " - " + val[1]
        return desc
    },
    onChange() {
        console.log('ggg')
        this.$emit('fetchStock', {
            limit: this.limit,
            offset: this.offset,
            mode: this.mode,
            startPercentage: this.stockPercentage[0],
            endPercentage: this.stockPercentage[1],
            startStockPrice: this.stockPrice[0],
            endStockPrice: this.stockPrice[1]
        });
    },
    onNext() {
       this.offset = this.offset + this.limit
       this.$emit('fetchStock', {
            limit: this.limit,
            offset: this.offset,
            mode: this.mode,
            startPercentage: this.stockPercentage[0],
            endPercentage: this.stockPercentage[1],
            startStockPrice: this.stockPrice[0],
            endStockPrice: this.stockPrice[1]
        });

    },
    onBack() {
       if (this.offset - this.limit >= 0) {
        this.offset = this.offset - this.limit
        this.$emit('fetchStock', {
              limit: this.limit,
              offset: this.offset,
              mode: this.mode,
              startPercentage: this.stockPercentage[0],
              endPercentage: this.stockPercentage[1],
              startStockPrice: this.stockPrice[0],
              endStockPrice: this.stockPrice[1]
          });

       }
       
    },
  },
};
</script>

<style scoped>
.ant-card-bordered {
    border: 1px solid #eaeaea;
}

.ant-card-head-title {
    color: #4d4d4d;
}
</style>